import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const noZero1 = (rule, value,callback)=>{
    if(value < 0 ){
        callback(new Error(t('currencySet.rule1')))
    }else{
        callback()
    }
}
const mainRules = {
    tenantId: [{ required: true, message: t('inputTit.xuanze'), trigger: 'change' }],
    appId: [{ required: true, message: t('inputTit.xuanze'), trigger: 'change' }],
    allCoin: [{ required: true, message: t('inputTit.xuanze'), trigger: 'change' }],
    // contractAddr: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    collectionWaterline: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    hotWalletToplimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    hotWalletWarnLimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    gasFeeWarnLimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    coldWalletToplimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    minCollectionAmount: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    googleCode: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
}
const moreRules = {
    minWithdrawAmount: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    maxWithdrawAmountDay: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    maxWithdrawAmountOnce: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    dayTime: [{ required: true, message: t('inputTit.xuanze'), trigger: 'change' }],
    dayOnceAuditFree: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    dayOneAuditNumLimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    dayOneAuditAmountLimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    dayAllAuditAmountLimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    nightTime: [{ required: true, message: t('inputTit.xuanze'), trigger: 'change' }],
    nightOnceAuditFree: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    nightOneAuditNumLimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    nightOneAuditAmountLimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
    nightAllAuditAmountLimit: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' },{ validator: noZero1, trigger: 'blur' }],
}

export default{
    mainRules,moreRules
}